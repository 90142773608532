import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				Меню | Remartastyle Sport Pub
			</title>
			<meta name={"description"} content={"Наше меню не залишить вас байдужим"} />
			<meta property={"og:title"} content={"Меню | Remartastyle Sport Pub"} />
			<meta property={"og:description"} content={"Наше меню не залишить вас байдужим"} />
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/restaurant.png?v=2023-10-05T13:11:37.351Z"} />
		</Helmet>
		<Components.Header />
		<Section
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			padding="80px 0px 60px 0px"
			quarkly-title="Team"
			box-sizing="border-box"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				text-transform="uppercase"
				text-align="center"
				font="--base"
				opacity="0.5"
			>
				Menu
			</Text>
			<Text
				color="--dark"
				md-margin="0px 0px 32px 0px"
				font="--headline2"
				letter-spacing="1px"
				width="100%"
				lg-margin="0px 0px 42px 0px"
				md-font="--headline3"
				margin="0px 0px 72px 0px"
				text-align="center"
			>
				Наше меню
			</Text>
			<Box
				sm-grid-template-columns="1fr"
				min-width="10px"
				display="grid"
				width="100%"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://uploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14:29:55.646Z"
					display="block"
					width="100% border-box"
					srcSet="https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651eafdc2dc70e00207e5fef/images/menu.png?v=2023-10-05T14%3A29%3A55.646Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Photos />
		<Components.Footer />
	</Theme>;
});